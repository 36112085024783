import { default as aboutSei6iqwbeSMeta } from "/srv/cnc/production/client/pages/about.vue?macro=true";
import { default as dataview6hXRZg8HmXMeta } from "/srv/cnc/production/client/pages/admin/dataview.vue?macro=true";
import { default as imagesReoaiehTE1Meta } from "/srv/cnc/production/client/pages/admin/images.vue?macro=true";
import { default as _91taskId_93QNN8pH9eGBMeta } from "/srv/cnc/production/client/pages/admin/task/[taskId].vue?macro=true";
import { default as tasksFcTnjQftEbMeta } from "/srv/cnc/production/client/pages/admin/tasks.vue?macro=true";
import { default as users1usXHl63kvMeta } from "/srv/cnc/production/client/pages/admin/users.vue?macro=true";
import { default as adminwzpAgeu6bNMeta } from "/srv/cnc/production/client/pages/admin.vue?macro=true";
import { default as index0FlINohhWnMeta } from "/srv/cnc/production/client/pages/index.vue?macro=true";
import { default as issues7roFcR6tfpMeta } from "/srv/cnc/production/client/pages/issues.vue?macro=true";
import { default as loginPP4TCg5obPMeta } from "/srv/cnc/production/client/pages/login.vue?macro=true";
import { default as logoutUTEpuI3SPhMeta } from "/srv/cnc/production/client/pages/logout.vue?macro=true";
import { default as indexzoNece5tn8Meta } from "/srv/cnc/production/client/pages/pathapp/index.vue?macro=true";
import { default as _91imageId_93vWEorxarEHMeta } from "/srv/cnc/production/client/pages/pathapp/task-[taskId]/[imageId].vue?macro=true";
import { default as index9HKTZjkPFWMeta } from "/srv/cnc/production/client/pages/pathapp/task-[taskId]/index.vue?macro=true";
import { default as task_45_91taskId_93FJiqs905x6Meta } from "/srv/cnc/production/client/pages/pathapp/task-[taskId].vue?macro=true";
import { default as pathappGopbU3xvbHMeta } from "/srv/cnc/production/client/pages/pathapp.vue?macro=true";
export default [
  {
    name: aboutSei6iqwbeSMeta?.name ?? "about",
    path: aboutSei6iqwbeSMeta?.path ?? "/about",
    meta: aboutSei6iqwbeSMeta || {},
    alias: aboutSei6iqwbeSMeta?.alias || [],
    redirect: aboutSei6iqwbeSMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/about.vue").then(m => m.default || m)
  },
  {
    name: adminwzpAgeu6bNMeta?.name ?? "admin",
    path: adminwzpAgeu6bNMeta?.path ?? "/admin",
    meta: adminwzpAgeu6bNMeta || {},
    alias: adminwzpAgeu6bNMeta?.alias || [],
    redirect: adminwzpAgeu6bNMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: dataview6hXRZg8HmXMeta?.name ?? "admin-dataview",
    path: dataview6hXRZg8HmXMeta?.path ?? "dataview",
    meta: dataview6hXRZg8HmXMeta || {},
    alias: dataview6hXRZg8HmXMeta?.alias || [],
    redirect: dataview6hXRZg8HmXMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/admin/dataview.vue").then(m => m.default || m)
  },
  {
    name: imagesReoaiehTE1Meta?.name ?? "admin-images",
    path: imagesReoaiehTE1Meta?.path ?? "images",
    meta: imagesReoaiehTE1Meta || {},
    alias: imagesReoaiehTE1Meta?.alias || [],
    redirect: imagesReoaiehTE1Meta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/admin/images.vue").then(m => m.default || m)
  },
  {
    name: _91taskId_93QNN8pH9eGBMeta?.name ?? "admin-task-taskId",
    path: _91taskId_93QNN8pH9eGBMeta?.path ?? "task/:taskId()",
    meta: _91taskId_93QNN8pH9eGBMeta || {},
    alias: _91taskId_93QNN8pH9eGBMeta?.alias || [],
    redirect: _91taskId_93QNN8pH9eGBMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/admin/task/[taskId].vue").then(m => m.default || m)
  },
  {
    name: tasksFcTnjQftEbMeta?.name ?? "admin-tasks",
    path: tasksFcTnjQftEbMeta?.path ?? "tasks",
    meta: tasksFcTnjQftEbMeta || {},
    alias: tasksFcTnjQftEbMeta?.alias || [],
    redirect: tasksFcTnjQftEbMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/admin/tasks.vue").then(m => m.default || m)
  },
  {
    name: users1usXHl63kvMeta?.name ?? "admin-users",
    path: users1usXHl63kvMeta?.path ?? "users",
    meta: users1usXHl63kvMeta || {},
    alias: users1usXHl63kvMeta?.alias || [],
    redirect: users1usXHl63kvMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/admin/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index0FlINohhWnMeta?.name ?? "index",
    path: index0FlINohhWnMeta?.path ?? "/",
    meta: index0FlINohhWnMeta || {},
    alias: index0FlINohhWnMeta?.alias || [],
    redirect: index0FlINohhWnMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: issues7roFcR6tfpMeta?.name ?? "issues",
    path: issues7roFcR6tfpMeta?.path ?? "/issues",
    meta: issues7roFcR6tfpMeta || {},
    alias: issues7roFcR6tfpMeta?.alias || [],
    redirect: issues7roFcR6tfpMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/issues.vue").then(m => m.default || m)
  },
  {
    name: loginPP4TCg5obPMeta?.name ?? "login",
    path: loginPP4TCg5obPMeta?.path ?? "/login",
    meta: loginPP4TCg5obPMeta || {},
    alias: loginPP4TCg5obPMeta?.alias || [],
    redirect: loginPP4TCg5obPMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutUTEpuI3SPhMeta?.name ?? "logout",
    path: logoutUTEpuI3SPhMeta?.path ?? "/logout",
    meta: logoutUTEpuI3SPhMeta || {},
    alias: logoutUTEpuI3SPhMeta?.alias || [],
    redirect: logoutUTEpuI3SPhMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: pathappGopbU3xvbHMeta?.name ?? undefined,
    path: pathappGopbU3xvbHMeta?.path ?? "/pathapp",
    meta: pathappGopbU3xvbHMeta || {},
    alias: pathappGopbU3xvbHMeta?.alias || [],
    redirect: pathappGopbU3xvbHMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/pathapp.vue").then(m => m.default || m),
    children: [
  {
    name: indexzoNece5tn8Meta?.name ?? "pathapp",
    path: indexzoNece5tn8Meta?.path ?? "",
    meta: indexzoNece5tn8Meta || {},
    alias: indexzoNece5tn8Meta?.alias || [],
    redirect: indexzoNece5tn8Meta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/pathapp/index.vue").then(m => m.default || m)
  },
  {
    name: task_45_91taskId_93FJiqs905x6Meta?.name ?? undefined,
    path: task_45_91taskId_93FJiqs905x6Meta?.path ?? "task-:taskId()",
    meta: task_45_91taskId_93FJiqs905x6Meta || {},
    alias: task_45_91taskId_93FJiqs905x6Meta?.alias || [],
    redirect: task_45_91taskId_93FJiqs905x6Meta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/pathapp/task-[taskId].vue").then(m => m.default || m),
    children: [
  {
    name: _91imageId_93vWEorxarEHMeta?.name ?? "pathapp-task-taskId-imageId",
    path: _91imageId_93vWEorxarEHMeta?.path ?? ":imageId()",
    meta: _91imageId_93vWEorxarEHMeta || {},
    alias: _91imageId_93vWEorxarEHMeta?.alias || [],
    redirect: _91imageId_93vWEorxarEHMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/pathapp/task-[taskId]/[imageId].vue").then(m => m.default || m)
  },
  {
    name: index9HKTZjkPFWMeta?.name ?? "pathapp-task-taskId",
    path: index9HKTZjkPFWMeta?.path ?? "",
    meta: index9HKTZjkPFWMeta || {},
    alias: index9HKTZjkPFWMeta?.alias || [],
    redirect: index9HKTZjkPFWMeta?.redirect,
    component: () => import("/srv/cnc/production/client/pages/pathapp/task-[taskId]/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]